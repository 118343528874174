.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #461370;
}

.App-link {
  color: #61dafb;
}
.hide {
  display: none;
}
.myDIV:hover+.hide {
  display: block;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  font-size: 12;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-width {
  width: 80vh;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}

.btn {
  position: relative;
  border: 2px solid #da4453;
  padding: 5px;
  color: #fff;
  margin-left: 5.3rem;
  border-radius: 5px;
  /* background-color: #da4453; */
  position: relative;
  overflow: hidden;
  cursor: pointer;
  text-transform: capitalize;
  font-family: monospace;
  letter-spacing: -1px;
}

.btn [class^=btn__text] {
  font-size: 20px;
}

.btn .btn__text-dynamic,
.btn .btn__text-dynamic-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: all ease 0.5s;
}

.btn .btn__text-dynamic {
  background-color: #da4453;
  overflow: hidden;
}

.btn:hover .btn__text-dynamic {
  transform: translateX(-100%);
}

.btn:hover .btn__text-dynamic-inner {
  /* background-color: #da4453; */
  transform: translateX(100%);
}

.btn--animation-from-right:hover .btn__text-dynamic {
  transform: translateX(100%);
}

.btn--animation-from-right:hover .btn__text-dynamic-inner {
  
  transform: translateX(-100%);
}

.btn--animation-from-top:hover .btn__text-dynamic {
  transform: translateY(100%);
}

.btn--animation-from-top:hover .btn__text-dynamic-inner {
  transform: translateY(-100%);
}

.btn--animation-from-bottom:hover .btn__text-dynamic {
  transform: translateY(-100%);
}

.btn--animation-from-bottom:hover .btn__text-dynamic-inner {
  transform: translateY(100%);
}

.carouselContainer {
  /* width: 100px; */

}
.carouselContainerItem{


  padding: 10px;
  /* background-color: black; */

}
.dotesClass{

}

