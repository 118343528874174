@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SourceSansPro-Black";
  src: url("./assets/fonts/SourceSansPro-Black.otf");
}

@font-face {
  font-family: "SourceSansPro-Bold";
  src: url("./assets/fonts/SourceSansPro-Bold.otf");
}

@font-face {
  font-family: "SourceSansPro-Light";
  src: url("./assets/fonts/SourceSansPro-Light.otf");
}

@font-face {
  font-family: "SourceSansPro-Regular";
  src: url("./assets/fonts/SourceSansPro-Regular.otf");
}
