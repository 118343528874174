.container {
  display: flex;
  align-items: baseline;
  background-image: url("./logo.jpg");
  background-repeat: no-repeat;
  border-radius: 100px;
  background-size: cover;
  position: fixed;
  border-radius: 20px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 10px;

  z-index: 9999;
}
.container-cancle {
  display: flex;
  align-items: baseline;
  background-image: url("./cancle.png");
  background-repeat: no-repeat;
  border-radius: 100px;
  background-size: cover;
  position: fixed;
  border-radius: 10px;
  background-color: red;
  cursor: pointer;
  width: 45px;
  height: 45px;
  bottom: 20px;
  right: 10px;
  z-index: 9999;
}

.chatPortal {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  background-color: white;
  border-radius: 100px;
  position: fixed;
  border-radius: 20px;
  border-color: black;
  border-width: 1px;
  cursor: pointer;
  padding: 3px;
  height: 550px;
  bottom: 70px;
  right: 10px;
  z-index: 9999;
}

@media only screen and (max-width: 420px) {
  .container {
    width: 40px;
    height: 40px;
    bottom: 10px;
    right: 20px;
  }
  .chatPortal {
    width: 100%;
    height: 550px;
    bottom: 1px;
    right: -30px;
  }
  .container-cancle {
    width: 25px;
    height: 25px;
    bottom: 20px;
    right: 20px;
  }
}
